<template>
  <b-dropdown
    position="is-bottom-left"
    animation=""
    class="more-nav">
    <b-icon
      slot="trigger"
      class="more-nav-icon"
      icon="bars"/>
    <div
      v-for="(section, i) in sections"
      :key="`more_nav_section_${section.section || i}`"
      class="more-nav-section">
      <hr
        v-if="i !== 0"
        class="dropdown-divider">
      <div
        v-if="section.section"
        class="more-nav-section-header">
        {{ section.section }}
      </div>
      <b-dropdown-item
        v-for="nav in section.items"
        :key="`more_nav_${nav.label}`"
        hasLink>
        <component
          :is="nav.path ? 'router-link' : 'a'"
          :to="nav.path"
          @click="nav.click ? nav.click() : null">
          {{ nav.label }}
        </component>
      </b-dropdown-item>
    </div>
  </b-dropdown>
</template>

<script>
export default {
  name: 'more-nav',
  props: {
    sections: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.more-nav {
  .more-nav-icon {
    display: flex;
    color: $white;
    cursor: pointer;
  }

  .more-nav-section {
    .more-nav-section-header {
      padding: 0 $space-m;
      margin-bottom: $space-xxs;
      font-weight: bold;
      @extend %small;
    }
  }
}
</style>
